<template>
  <div>
    <vs-card id="customHeight">
      <div class="justify-between flex flex-no-wrap">
        <h3 class="mt-3 mb-10 pl-2">Balances</h3>
      </div>
      <div class="flex items-center mb-4">
        <div class="flex items-center w-full md:w-1/4 p-2">
          <vs-select
            placeholder="Select an Organisation"
            width="100%"
            label="Organisation"
            name="organization"
            v-model="selectedOrg"
            autocomplete
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="item.name"
              v-for="(item, index) in organizations"
            />
          </vs-select>
        </div>
        <div class="flex items-center w-full md:w-1/5 p-2">
          <vs-select
            width="100%"
            label="Clinic"
            name="clinic"
            v-model="selectedClinic"
            autocomplete
            :disabled="selectedOrg ? false : true"
          >
            <vs-select-item
              :key="index"
              :value="item.clinicId"
              :text="item.name"
              v-for="(item, index) in clinicsList"
            />
          </vs-select>
        </div>
      </div>
      <template>
        <div
          id="data-list-list-view"
          class="data-list-container"
          v-if="selectedOrg"
        >
          <vs-table
            class="hidden md:block"
            ref="table"
            :data="drugEntries"
            :noDataText="'No data found'"
          >
            <template slot="thead">
              <vs-th sort-key="productName" class="customNameStyle"
                >Product Name</vs-th
              >
              <vs-th sort-key="balance" class="customBalanceStyle"
                >Drugbook Balance</vs-th
              >
              <vs-th class="customActionStyle"></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.productName">{{ tr.productName }}</vs-td>
                <vs-td
                  :data="tr.balance"
                  :class="tr.balance < 0 && 'negativeText'"
                  >{{
                    `${parseFloat(tr.balance).toFixed(2)}${formattedUnit(
                      tr.unit.toLowerCase()
                    )}`
                  }}</vs-td
                >
                <vs-td :data="tr" class="flex items-center justify-center">
                  <vs-button
                    type="flat"
                    class="flex m-0 p-0 justify-end hover:bg-transparent"
                    @click="redirectToDrugbook(tr)"
                  >
                    View Drugbook</vs-button
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="md:hidden mt-3">
            <div
              :key="indextr"
              v-for="(tr, indextr) in drugEntries"
              class="flex items-center justify-between p-2 py-4 m-2 borderCustom"
              @click="redirectToDrugbook(tr)"
            >
              <span class="basic-text">{{ tr.productName }}</span>
              <div class="flex flex-row items-center">
                <span class="basic-text pr-4">Balance:</span>
                <vs-chip
                  transparent
                  class="basic-text text-center"
                  :color="tr.balance < 0 ? 'danger' : '#84CDB8'"
                >
                  {{
                    `${parseFloat(tr.balance).toFixed(2)}${formattedUnit(
                      tr.unit.toLowerCase()
                    )}`
                  }}
                </vs-chip>
              </div>
            </div>
          </div>
        </div>
        <div class="no-filter-view" v-else>
          <p>
            To view drugbook balances, please select the desired
            <strong>Organization</strong>
          </p>
        </div>
      </template>
    </vs-card>
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import _ from "lodash";
import moment from "moment";

export default {
  data: () => ({
    clinicsList: [],
    drugEntries: [],
    selectedClinic: "",
    selectedOrg: "",
    clinicData: [],
    clinicList: [],
    isMobileView: null,
    selectedDrug: null,
    organizations: [],
  }),
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationAllClinics",
      "fetchOrganizationWithDrugEntries",
    ]),
    ...mapActions("drugbook", [
      "fetchDrugbookTotalBalance",
      "getAssociatedClinics",
    ]),
    async getOrganizationList() {
      try {
        const res = await this.fetchOrganizationWithDrugEntries();
        this.organizations = res.data.data;
      } catch (error) {
        console.log("ERR: ", error);
      }
    },
    async fetchclinics(orgId, userId) {
      try {
        const payload = {
          userId: userId,
          orgId: orgId,
        };
        const res = await this.getAssociatedClinics(payload);
        this.clinicsList = res.data.data
          .filter((data) => data.clinicName)
          .map((item) => {
            return {
              clinicId: item._id,
              name: item.clinicName,
              clinicsCount: item.clinicsCount,
              drugEntries: item.drugEntries,
            };
          })
          .sort((a, b) => {
            let nameA = a.name.toLowerCase();
            let nameB = b.name.toLowerCase();
            if (nameA > nameB) {
              return 1;
            } else {
              return -1;
            }
          });
        if (this.selectedClinic !== res.data.data[0]._id) {
          this.selectedClinic = res.data.data[0]._id;
        }
      } catch (e) {
        console.error("ERR: ", e);
      }
    },
    async getDrugEntries() {
      try {
        this.$vs.loading();
        const res = await this.fetchDrugbookTotalBalance(this.selectedClinic);
        this.drugEntries = res.data.data;
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        console.error("ERR: ", error);
      }
    },
    redirectToDrugbook(e) {
      let filterYear = new Date(e.entryDate).getFullYear();
      let filterMonth = moment(e.entryDate).format("MM");
      this.$router.push({
        path: "drugbook",
        query: {
          organizationId: this.selectedOrg,
          selectedMonth: filterMonth,
          selectedYear: filterYear,
          selectedDrug: e.productId,
          selectedClinic: this.selectedClinic,
        },
      });
    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
    formattedUnit(unit) {
      if (["unit", "units"].includes(unit)) {
        return "u";
      }

      return unit;
    },
  },
  watch: {
    selectedOrg: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const orgOwnerId = _.filter(
          this.organizations,
          (e) => e._id === newVal
        )[0].orgOwner[0].userId;
        this.fetchclinics(newVal, orgOwnerId);
      }
    },
    selectedClinic: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getDrugEntries();
        this.clinicData = this.clinicsList.filter(
          (e) => e.clinicId === newVal
        )[0];
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
  },
  created() {
    this.getOrganizationList();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>
  
  <style scoped lang="scss">
.no-filter-view {
  background-color: rgba(185, 185, 185, 0.13);
  padding: 5em 15em;
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
  border-radius: 7px;
  P {
    color: rgba(7, 66, 48, 0.58);
    font-size: 1.125rem;
  }
}
.borderCustom {
  border: 1px solid rgba(7, 66, 48, 0.5);
  border-radius: 8px;
}
.basic-text {
  color: rgba(7, 66, 48, 1);
  font-size: 14px;
}
.negativeText {
  color: red;
}
.customNameStyle {
  width: 70%;
}
.customBalanceStyle {
  width: 15%;
}
.customActionStyle {
  width: 15%;
}
</style>
  
  <style>
#customHeight .vs-card--content {
  min-height: 60dvh;
}
.popUpCustom .vs-popup--content {
  overflow: auto;
}
.con-vs-popup > .vs-popup {
  width: 450px;
  height: auto;
}
</style>
  